import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { registerRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Tab, Tabs, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import {
  sendLoginErrorEvent,
  sendLoginPageEvent,
  sendSignInInteractionEvents,
} from '@utils/analytics'
import { useLoginMutation } from '@src/mutations/LoginMutation.generated'
import { useFetchThirdPartyTokenMutation } from '@src/mutations/OauthTokenMutation.generated'
import { useValidateMobileSecurityCodeMutation } from '@src/mutations/ValidateMobileSecurityCodeMutation.generated'
import { useUserSession } from '@utils/useUserSession'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { OriginalError, extractData } from '@utils/errorHandler'
import messages from '@utils/messages'
import LoginFormBody from './LoginFormBody'
import { useCookies } from '@src/utils/cookies'
import storeValues from '@utils/storeValues'
import SpacingBox from '@src/components/SpacingBox'
import useResponsiveSizes from '@utils/useResponsiveSizes'
import GlobalAlert from '@src/components/GlobalAlert'
import vrStyles from '@src/styles/utils/vrStyles'
import {
  useChinaUser,
  useKoreaUser,
  useCountryUser,
} from '@src/utils/useChinaUser'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import MobileLoginBody from '@src/routes/Login/MobileLoginBody'
import Box from '@src/components/Box'
import TCUpdateDialog from '@src/components/TCUpdateDialog'

import {
  getWithExpiry,
  rememberUserNameOrNot,
} from '@utils/localStorageWithExpiry'
import loginStorage from '@src/utils/loginStorage'
import LoginModal from './LoginModal'
import { sendMobileLoginEvent } from '@utils/tealiumAnalytics'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => {
  const { vr2, vr4, vr8 } = vrStyles
  return createStyles({
    vr2,
    vr4,
    vr8,
    divider: {
      margin: theme.spacing(6, 0),
    },
    loginButtonRowDivider: {
      margin: theme.spacing(0, 0, 6, 0),
    },
    cancelLink: {
      color: theme.palette.primary.main,
      fontWeight: 900,
    },
    tabs: {
      marginBottom: '20px',
    },
    tab: {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: 900,
      color: theme.palette.text.primary,
      minWidth: '50px',
      padding: 0,
    },
    verticalDivider: {
      height: '22px',
      margin: '0 20px',
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
      alignSelf: 'center',
    },
    textWrap: {
      wordWrap: 'break-word',
      paddingRight: '32px',
    },
  })
})

interface TermValue {
  termUrl: string
  termKey: string
  agree: boolean
}

export interface LoginFormValues {
  userName: string
  password: string
  rememberMe?: boolean
}

export interface MobileLoginFormValues {
  mobile: string
  securityCode: string
}

export enum MobileLoginSceneId {
  Registration = '1',
  Login = '2',
  Profile = '3',
}

export interface LoginProps {
  isThirdPartyUser: boolean
  handleThirdPartyError: () => void
}

export interface TokenInput {
  responseType: string
  redirectUri: string
  state: string
  clientId: string
  username: string
  password: string
  activeToken: string
}

export interface PatchSitePreference {
  piiDataConsentAcceptanceFlag: boolean | null | undefined
  isGeneralTermsConditionDateExpiredFlag: boolean | null | undefined
  isSiteUseTermsDateExpiredFlag: boolean | null | undefined
  accessToken: string
}

const LoginForm: React.FC<LoginProps> = (props) => {
  const router = useRouter()
  const intl = useIntl()
  const classes = useStyles()
  const getSize = useResponsiveSizes()
  const { userSessionActions } = useUserSession()
  const [cookies] = useCookies(['_ga'])
  const [redirectToForgotPassword, setRedirectToForgotPassword] =
    useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [loginWarning, setLoginWarning] = useState('')
  const [lockNotificationEmail, setLockNotificationEmail] = useState('')
  const [useradLink, setUserAdLink] = useState('')
  const [tabValue, setTabValue] = useState(0)
  const [tcUpdateOpen, setTcUpdateOpen] = useState<boolean>(false)
  const [agreeClicked, setAgreeClicked] = useState<boolean | null>(false)
  const [terms, setTerms] = useState<TermValue[]>([])
  const [loginMutation, { client }] = useLoginMutation()
  const [validateMobileSecurityCode] = useValidateMobileSecurityCodeMutation()
  const [FetchThirdPartyTokenMutation] = useFetchThirdPartyTokenMutation()
  const isChinaUser = useChinaUser()
  const [userName, setUserName] = React.useState('')
  const [patchSitePreference, setPatchSitePreference] =
    useState<PatchSitePreference>({
      piiDataConsentAcceptanceFlag: false,
      isGeneralTermsConditionDateExpiredFlag: false,
      isSiteUseTermsDateExpiredFlag: false,
      accessToken: '',
    })
  const [participantId, setParticipantId] = React.useState<string>('')
  const [rememberMe, setRememberMe] = React.useState<boolean | undefined>(false)
  const [accessToken, setAccessToken] = React.useState('')
  const [rememberMeChecked, setRememberMeChecked] = React.useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const isKoreaUser = useKoreaUser()
  useEffect(() => {
    const hasUserName = getWithExpiry('userName') !== null
    setRememberMeChecked(hasUserName)
  }, [])
  let registerPath = registerRoute.index()
  // Checks for redirects coming from internal links as well as third party redirect_Uri
  const isThirdParty = props.isThirdPartyUser
  const { redirect, redirect_uri } = router?.query || ''
  const redirectLink = redirect_uri && isThirdParty ? redirect_uri : redirect
  const clientId = router?.query?.client_id || ''
  // DTAQ accept invite token
  const [tempToken] =
    router.query.redirect?.match(/tempaccesstoken([^&]*)/) ?? []
  const countryCode = router.query.country
  const isDtaqCountryWithToken = Boolean(countryCode === 'JP' && tempToken)
  const hasTokenToAcceptAccess =
    isDtaqCountryWithToken && tempToken ? tempToken.split('=')[1] : false

  // WIBF-1717 - Add State parameter to the Third Party URL
  const clientState = router?.query?.state || ''
  const clientName = router?.query?.client_name || ''
  const responseType = router?.query?.response_type || ''

  const { userIsLoggedIn, currentUser } = useCurrentUser()
  const allowActiveOauth = cookies.accessToken && isThirdParty && userIsLoggedIn

  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => setIsMounted(true), [])
  const isTHUser = useCountryUser('TH')
  const isVNUser = useCountryUser('VN')
  const isPHUser = useCountryUser('PH')

  if (redirectLink && isThirdParty) {
    registerPath += `?authn_t=oauth&client_id=${clientId}&redirect=${encodeURIComponent(
      redirectLink
    )}&state=${clientState}&response_type=${responseType}&client_name=${clientName}`
  } else if (redirectLink) {
    registerPath += `?redirect=${encodeURIComponent(redirectLink)}`
  }
  //To Handle the Pipeline user's complete profile flow. Once Password is updated need ask user to login again.
  let askLoginAgain = false
  if (router?.query && router?.query?.pipelineProfileCompleted) {
    askLoginAgain = true
  }
  // Handles third party authentication for oauth2
  const handleThirdPartyLogin = async (values: LoginFormValues) => {
    const tokenParams: TokenInput = {
      responseType: responseType,
      redirectUri: redirect_uri,
      state: clientState,
      clientId,
      username: values?.userName || '',
      password: values?.password || '',
      activeToken: cookies.accessToken || '',
    }
    const res = await FetchThirdPartyTokenMutation({
      variables: tokenParams,
    })
    if (res.data?.fetchThirdPartyToken?.response_status_code === '302') {
      if (redirectLink) {
        const tokenRes = res.data?.fetchThirdPartyToken
        Object.keys(tokenRes).forEach((key) => {
          if (tokenRes[key] === null || key === '__typename') {
            delete tokenRes[key]
          }
        })
        const queryParams = new URLSearchParams(
          tokenRes as Record<string, string>
        ).toString()
        const clientUri = `${redirectLink}?${queryParams}`
        window.location.href = clientUri
      }
    } else {
      // display errors to screen
      props.handleThirdPartyError()
    }
  }

  const completeLogin = async (
    accessToken: string,
    userName: string,
    rememberMe?: boolean
  ) => {
    if (accessToken) {
      if (isChinaUser) {
        rememberUserNameOrNot(rememberMeChecked, userName)
        loginStorage.set({ rememberMe: rememberMeChecked })
      }
      setLoginError('')
      if (rememberMe != null) {
        sendLoginPageEvent({
          gaId: cookies._ga,
          actionTitle: `Persistent Login - ${rememberMe ? 'Yes' : 'No'}`,
        })
        loginStorage.set({ rememberMe })
      }
      await userSessionActions.login(accessToken, storeValues.sial, true)
      setSubmitSuccess(true)
    }
  }

  const handleLogin = async (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => {
    try {
      actions.setStatus(undefined) // Remove any existing BE error messages
      sendLoginPageEvent({
        gaId: cookies._ga,
        actionTitle: 'Sign into your profile',
      })
      if (router?.query?.sessionExpired === 'true') {
        sendLoginPageEvent({
          gaId: cookies._ga,
          actionTitle: 'reauthentication',
        })
      }
      const { data } = await loginMutation({ variables: values })
      const accessToken = data && data.login && data.login.accessToken
      const participantId = data && data.login && data.login.participantId
      const userAdLinkValue =
        data && data.login && data.login.user && data.login.user.userAdLink
      const hasExpiredDates =
        data &&
        data.login &&
        (data.login.isGeneralTermsConditionDateExpired ||
          data.login.isSiteUseTermsDateExpired ||
          ((isKoreaUser || isTHUser || isPHUser || isVNUser) &&
            !data.login.piiDataConsentAcceptance))
      setPatchSitePreference({
        piiDataConsentAcceptanceFlag:
          data && data.login && data.login.piiDataConsentAcceptance,
        isGeneralTermsConditionDateExpiredFlag:
          data && data.login && data.login.isGeneralTermsConditionDateExpired,
        isSiteUseTermsDateExpiredFlag:
          data && data.login && data.login.isSiteUseTermsDateExpired,
        accessToken: accessToken || '',
      })

      if (accessToken) {
        if (userAdLinkValue) {
          setUserAdLink(userAdLinkValue)
        } else {
          setUserAdLink('')
        }

        if (participantId && hasExpiredDates && !isChinaUser) {
          setAccessToken(accessToken)
          setUserName(values.userName)
          setParticipantId(participantId)
          setRememberMe(values.rememberMe)
          setModalOpen(true)
        } else {
          completeLogin(accessToken, values.userName, values.rememberMe)
        }
      }
    } catch (error) {
      const { errors, hasError } = extractData(error as OriginalError)
      let errorMsg

      if (errors.length > 0) {
        const { code, misc } = errors[0]

        if (
          hasError('USER_PASSWORD_EXPIRED') &&
          misc &&
          misc.ecommerceErrors &&
          misc.ecommerceErrors.length > 0 &&
          misc.ecommerceErrors[0].paramList &&
          misc.ecommerceErrors[0].paramList.length > 1
        ) {
          return setRedirectToForgotPassword(true)
        } else if (
          hasError('USER_ACCOUNT_LOCKED_90DAYS_EXPIRY') &&
          misc &&
          misc.ecommerceErrors &&
          misc.ecommerceErrors.length > 0 &&
          misc.ecommerceErrors[0].paramList &&
          misc.ecommerceErrors[0].paramList.length > 0
        ) {
          setLockNotificationEmail(misc.ecommerceErrors[0].paramList[0])
          errorMsg = intl.formatMessage(
            messages.USER_ACCOUNT_LOCKED_90DAYS_EXPIRY
          )
        } else if (
          //b2c users are not allowed to login from B2B domain.
          hasError('B2C_NOT_AUTHORIZED_TO_ACCESS') &&
          misc &&
          misc.ecommerceErrors &&
          misc.ecommerceErrors.length > 0 &&
          misc.ecommerceErrors[0].paramList &&
          misc.ecommerceErrors[0].paramList.length > 0
        ) {
          errorMsg =
            intl.formatMessage(messages.B2C_NOT_AUTHORIZED_TO_ACCESS) +
            intl.formatMessage(messages.B2C_PLEASE_VISIT_MESSAGE, {
              url: misc.ecommerceErrors[0].paramList[0],
            })
        } else {
          const errorKey = messages[code]
            ? messages[code]
            : messages.USER_UNABLE_TO_LOGIN
          sendLoginErrorEvent({ message: errorKey.defaultMessage })
          errorMsg = intl.formatMessage(errorKey)
        }
      }

      actions.setSubmitting(false)
      setLoginError(errorMsg)
    }
  }

  const handleMobileLogin = async (
    values: MobileLoginFormValues,
    actions: FormikHelpers<MobileLoginFormValues>
  ) => {
    setLoginError('')
    setLoginWarning('')
    try {
      actions.setStatus(undefined)
      const { data } = await validateMobileSecurityCode({
        variables: { ...values, sceneId: MobileLoginSceneId.Login },
      })
      const accessToken =
        data &&
        data.validateMobileSecurityCode &&
        data.validateMobileSecurityCode.accessToken
      if (accessToken) {
        setLoginError('')
        await userSessionActions.login(accessToken, storeValues.sial, true)
        setSubmitSuccess(true)
      }
    } catch (error) {
      const { errors } = extractData(error as OriginalError)
      if (errors.length > 0) {
        const { code } = errors[0]
        const errorKey = messages[code]
          ? messages[code]
          : messages.USER_UNABLE_TO_LOGIN
        sendLoginErrorEvent({ message: errorKey.defaultMessage })
        const errorMsg = intl.formatMessage(errorKey)
        setLoginError(errorMsg)
      }
      actions.setSubmitting(false)
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    if (newValue === 2) {
      sendMobileLoginEvent(cookies._ga)
    }
  }

  const renderMobileLogin = () => {
    return (
      <div>
        <FormattedMessage id="SIGN_IN" defaultMessage="Sign In">
          {(text) => (
            <Box mb={5} clone>
              <Typography variant="h2">{text}</Typography>
            </Box>
          )}
        </FormattedMessage>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabs}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            className={classes.tab}
            label={<FormattedMessage {...messages.PASSWORD_LOGIN} />}
          />
          <div className={classes.verticalDivider} />
          <Tab
            className={classes.tab}
            label={<FormattedMessage {...messages.MOBILE} />}
          />
        </Tabs>
        {tabValue === 0 && (
          <Formik
            initialValues={{
              userName: getWithExpiry('userName') || '',
              password: '',
              rememberMe: currentUser?.metadata.isRememberMeEnabled
                ? loginStorage.get()?.rememberMe || false
                : undefined,
            }}
            onSubmit={
              props.isThirdPartyUser ? handleThirdPartyLogin : handleLogin
            }
          >
            {(formikProps: FormikProps<LoginFormValues>) => (
              <LoginFormBody
                formikProps={formikProps}
                submitSuccess={submitSuccess}
                redirectToForgotPassword={redirectToForgotPassword}
                intl={intl}
                setLoginError={setLoginError}
                agreeClicked={agreeClicked}
                setTerms={setTerms}
                setTcUpdateOpen={setTcUpdateOpen}
                rememberMeChecked={rememberMeChecked}
                setRememberMeChecked={setRememberMeChecked}
                useradLink={useradLink}
              />
            )}
          </Formik>
        )}
        {tabValue === 2 && (
          <Formik
            initialValues={{ mobile: '', securityCode: '' }}
            onSubmit={handleMobileLogin}
          >
            {(formikProps: FormikProps<MobileLoginFormValues>) => (
              <MobileLoginBody
                formikProps={formikProps}
                submitSuccess={submitSuccess}
                intl={intl}
                setLoginError={setLoginError}
                setLoginWarning={setLoginWarning}
                setTabValue={setTabValue}
                agreeClicked={agreeClicked}
                setTerms={setTerms}
                setTcUpdateOpen={setTcUpdateOpen}
              />
            )}
          </Formik>
        )}
      </div>
    )
  }

  if (!client) return null
  return (
    <>
      <SpacingBox
        px={{ md: 12, lg: 14 }}
        py={{ md: 8 }}
        maxWidth={{ xs: 400, md: 472 }}
        mx={{ xs: 'auto', md: 0 }}
      >
        {(spacingProps) => (
          <Paper {...spacingProps} elevation={getSize({ xs: 0, md: 4 })}>
            {loginWarning.length > 0 && (
              <GlobalAlert severity="warning" className={classes.vr4}>
                {loginWarning}
              </GlobalAlert>
            )}
            {loginError.length > 0 ? (
              <GlobalAlert
                severity="error"
                className={clsx(classes.vr4, classes.textWrap)}
              >
                {lockNotificationEmail.length > 0 ? (
                  <FormattedMessage
                    {...messages.USER_ACCOUNT_LOCKED_90DAYS_EXPIRY}
                    values={{ email: lockNotificationEmail }}
                  />
                ) : (
                  <FormattedMessage
                    id="EMAIL_USERNAME_LOGIN_INCORRECT"
                    defaultMessage="The Username / Email Address or Password that was entered is incorrect"
                  />
                )}
              </GlobalAlert>
            ) : null}
            {askLoginAgain && (
              <GlobalAlert severity="info" className={classes.vr4}>
                <FormattedMessage {...messages.PROFILE_COMPLETE_LOGIN_AGAIN} />
              </GlobalAlert>
            )}
            {hasTokenToAcceptAccess && (
              <GlobalAlert className={classes.vr4} severity="info">
                <FormattedMessage {...messages.SIGN_IN_TO_ACCEPT_INVITE} />
              </GlobalAlert>
            )}
            {allowActiveOauth ? (
              <>
                <Typography component="h1" variant="h1" className={classes.vr2}>
                  <FormattedMessage {...messages.THIRD_PARTY_NOTICE} />
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.vr4}
                >
                  <FormattedMessage {...messages.THIRD_PARTY_DATA_SHARING} />
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    handleThirdPartyLogin({ userName: '', password: '' })
                  }
                >
                  <FormattedMessage {...messages.ACCEPT_CONTINUE} />
                </Button>
              </>
            ) : (
              <>
                {!isChinaUser ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h1"
                      className={classes.vr2}
                    >
                      {!isThirdParty ? (
                        <FormattedMessage {...messages.SIGN_IN_PROFILE} />
                      ) : (
                        <FormattedMessage
                          id="SIGN_IN"
                          defaultMessage="Sign In"
                        />
                      )}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      className={classes.vr8}
                    >
                      <FormattedMessage {...messages.LOGIN_DIRECTIONS} />
                    </Typography>
                    <Formik<LoginFormValues>
                      initialValues={{
                        userName: '',
                        password: '',
                        rememberMe: currentUser?.metadata.isRememberMeEnabled
                          ? loginStorage.get()?.rememberMe || false
                          : undefined,
                      }}
                      onSubmit={
                        props.isThirdPartyUser
                          ? handleThirdPartyLogin
                          : handleLogin
                      }
                    >
                      {(formikProps: FormikProps<LoginFormValues>) => (
                        <LoginFormBody
                          formikProps={formikProps}
                          submitSuccess={submitSuccess}
                          redirectToForgotPassword={redirectToForgotPassword}
                          intl={intl}
                          setLoginError={setLoginError}
                          agreeClicked={agreeClicked}
                          setTerms={setTerms}
                          setTcUpdateOpen={setTcUpdateOpen}
                          allowPersistentLogin={
                            isMounted && // Prevents SSR/localStorage mismatch
                            !!currentUser?.metadata.isRememberMeEnabled &&
                            !props.isThirdPartyUser
                          }
                          useradLink={useradLink}
                        />
                      )}
                    </Formik>
                  </>
                ) : (
                  renderMobileLogin()
                )}
                {isThirdParty ? (
                  <>
                    <FormattedMessage {...messages.THIRD_PARTY_LOGIN_NOTICE} />
                    <Divider
                      className={
                        isChinaUser
                          ? classes.loginButtonRowDivider
                          : classes.divider
                      }
                      light
                    />
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.vr2}
                    >
                      <FormattedMessage {...messages.DO_NOT_HAVE_AN_ACCOUNT} />
                    </Typography>
                    <Link href={registerPath} passHref>
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          sendLoginPageEvent({
                            gaId: cookies._ga,
                            actionTitle: 'Create a new profile',
                          })
                          sendSignInInteractionEvents({
                            action: 'visit register page',
                            section: "don't have an account",
                            element_type: 'button',
                            element_text: 'register',
                            link_url: registerPath,
                          })
                        }}
                      >
                        <FormattedMessage
                          id="REGISTER_TITLE"
                          defaultMessage="Register"
                        />
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Divider
                      className={
                        isChinaUser
                          ? classes.loginButtonRowDivider
                          : classes.divider
                      }
                      light
                    />
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.vr2}
                    >
                      <FormattedMessage {...messages.DO_NOT_HAVE_AN_ACCOUNT} />
                    </Typography>
                    {
                      // Finalized copy is not yet provided for this element - please
                      /* <Typography variant="body2" component="p" className={classes.vr4}>

            <FormattedMessage {...messages.CREATE_NEW_PROFILE_DESCRIPTION
            </Typography> */
                    }
                    <Link href={registerPath} passHref>
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          sendLoginPageEvent({
                            gaId: cookies._ga,
                            actionTitle: 'Create a new profile',
                          })
                          sendSignInInteractionEvents({
                            action: 'visit register page',
                            section: "don't have an account",
                            element_type: 'button',
                            element_text: 'register',
                            link_url: registerPath,
                          })
                        }}
                      >
                        <FormattedMessage {...messages.CREATE_NEW_PROFILE} />
                      </Button>
                    </Link>
                  </>
                )}
              </>
            )}
            {redirectLink && isThirdParty && (
              <>
                <Divider
                  className={
                    isChinaUser
                      ? classes.loginButtonRowDivider
                      : classes.divider
                  }
                  light
                />
                <Link href={redirectLink} passHref>
                  <a className={classes.cancelLink}>
                    {intl.formatMessage(messages.CANCEL_AND_RETURN, {
                      thirdParty: 'Previous Site',
                    })}
                  </a>
                </Link>
              </>
            )}
            {isChinaUser && (
              <TCUpdateDialog
                terms={terms}
                open={tcUpdateOpen}
                setTcUpdateOpen={setTcUpdateOpen}
                setAgreeClicked={setAgreeClicked}
              />
            )}
          </Paper>
        )}
      </SpacingBox>
      {!isChinaUser && (
        <LoginModal
          open={modalOpen}
          setModalOpen={setModalOpen}
          accessToken={accessToken}
          userName={userName}
          participantId={participantId}
          rememberMe={rememberMe}
          completeLogin={completeLogin}
          setLoginError={setLoginError}
          patchSitePreferences={patchSitePreference}
        />
      )}
    </>
  )
}

export default LoginForm
